<template>
  <header class="header">
    <div class="header__logo"></div>
  </header>
  <main>
    <h1>Official partner</h1>
    <h2 class="blue">of Paris Saint-Germain</h2>
    <h2
      class="subtitle"
      v-if="isMobile"
    >
      Register NOW<br />and receive a BONUS!
    </h2>
    <h2 v-else>Register NOW and receive a BONUS!</h2>
    <div
      class="promoCode"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="clickIconDone"
    >
      {{ promoCode }}
      <div
        :class="{
          'icon-default': iconDefault,
          'icon-hover': iconHover,
          'icon-done': iconDone,
        }"
      ></div>
    </div>
    <a
      class="btn"
      href="https://cropped.xyz/gv0es"
    >
      <div class="btn-1">
        <div class="btn-2">go to the website</div>
      </div>
    </a>
  </main>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      iconDefault: true,
      iconHover: false,
      iconDone: false,
      promoCode: 'PARIS1X',
    };
  },
  components: {},
  methods: {
    mouseOver() {
      this.iconDefault = false;
      this.iconHover = true;
    },
    mouseLeave() {
      this.iconDefault = true;
      this.iconHover = false;
      this.iconDone = false;
    },
    clickIconDone() {
      this.iconDefault = false;
      this.iconHover = false;
      this.iconDone = true;
      navigator.clipboard.writeText(this.promoCode);
    },
  },
  computed: {
    isMobile() {
      return document.documentElement.clientWidth <= 576;
    },
  },
};
</script>

<style lang="scss"></style>
